html {
  scroll-behavior: smooth;
}

.applyPage {
  border: none;
  box-sizing: border-box;
  font-family: "Inter", Tahoma, sans-serif;
  line-height: 1.5;
  margin: 0;
  height: 100%;
  padding: 0;
  background-color: white;
  white-space: normal;
  overflow: hidden;
}
.applyPage__waves {
  position: absolute;
  top: 80vh;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(../images/wave.png);
  background-size: 1000px 100px;
}
.applyPage__waves1 {
  animation: animate1 15s linear infinite;
  animation-delay: 0s;
}
.applyPage__waves2 {
  height: 120px;
  background-size: 750px 100px;
  opacity: 0.5;
  background-position-x: 200px;
  animation: animate2 9s linear infinite;
  animation-delay: 0s;
}
@keyframes animate1 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}
@keyframes animate2 {
  0% {
    background-position-x: 500px;
  }
  100% {
    background-position-x: -250px;
  }
}
.applyPage_linkTree {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  /*color: #fff; */
  color: var(--clr-text, #fff);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0.7em 1.5em;
  margin: 1.5em auto;
  /*background-color: #6aeb50; */
  background-color: var(--primary-green, #6aeb50);
  border-radius: 0.1em;
  box-shadow: 0 0 10px 0 #cfcfcf;
  border: 2px solid transparent;
  transition: transform 0.25s ease-in-out, opacity 0.25s linear;
}
.applyPage__btn {
  position: absolute;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: Arial;
  font-weight: 700;
  padding: 0.525em;
  margin-bottom: -12.5%;
  border-radius: 0.425em;
  box-shadow: 0 0 10px 0px rgb(207, 207, 207);
  border: 2px solid rgb(0, 0, 0, 0);
  /*border: 2px solid var(--primary-green, rgb(106, 235, 80)); */
  color: var(--clr-text, white);
  /*color: var(--primary-green, rgb(106, 235, 80)); 
  background-color: white; */
  background-color: var(--primary-green, rgb(106, 235, 80));
  left: 0;
  bottom: 0;
  right: 0;
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 15%;
  min-height: 20px;
  margin-top: 30px;
  transition: transform 250ms ease-in-out, opacity 250ms linear;
}
.applyPage a {
  text-decoration: none;
  color: white;
}

.applyPage_linkTree:hover,
.applyPage_linkTree:focus,
.applyPage_linkTree::after {
  transform: scale(1.03);
  border: 2px solid var(--primary-green, rgb(106, 235, 80));
  color: var(--primary-green, rgb(106, 235, 80));
  background-color: white;
  cursor: pointer;
}
.applyPage__btn:hover,
.applyPage__btn:focus,
.applyPage__btn::after {
  transform: scale(1.03);
  border: 2px solid var(--primary-green, rgb(106, 235, 80));
  color: var(--primary-green, rgb(106, 235, 80));
  background-color: white;
  cursor: pointer;
}
.applyPage__closed {
  position: absolute;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: Arial;
  font-weight: 700;
  padding: 0.525em;
  margin-bottom: -12.5%;
  border-radius: 0.425em;
  box-shadow: 0 0 10px 0px rgb(207, 207, 207);
  border: 2px solid var(--primary-green, rgb(106, 235, 80));
  color: var(--primary-green, rgb(106, 235, 80));
  background-color: white;
  left: 0;
  bottom: 0;
  right: 0;
  margin-left: 18%;
  margin-right: 18%;
  min-height: 20px;
  margin-top: 5%;
}

@media (max-width: 800px) {
  .applyPage__closed .applyPage__btn {
    position: inherit;
  }
}
.applyPage__showcase {
  text-align: center;
  display: inline-block;
  color: white;
  width: 100vw;
  font-size: clamp(1rem, calc(1vw + 0.5rem), 2.5rem);
  background-image: linear-gradient(
    rgb(89, 201, 209),
    var(--primary-green, rgb(106, 235, 80))
  );
  height: 80vh;
}

@supports (background-blend-mode: multiply) {
  .applyPage__showcase {
    background-image: linear-gradient(
      var(--primary-green),
      var(--lightwash-green),
      var(--baby-blue)
    );
    background-size: cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-repeat: repeat-x;
    background-position: center center;
  }
}
.applyPage__primary-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 20%;
}
.applyPage__primary-title {
  position: relative;
  float: left;
  left: 13%;
  top: 20%;
  font-size: var(--title-size);
}
.applyPage__showcase p {
  width: 80%;
}
.applyPage__primary-description {
  position: relative;
  float: left;
  left: 13%;
  top: 20%;
  text-align: left;
  font-size: clamp(18px, calc(1vw + 12px), 24px);
}
.applyPage__showcase h1,
.applyPage__showcase p {
  margin: 0;
  color: #ffffff;
}

@media (max-height: 600px) {
  .applyPage__waves {
    top: 80vh;
  }
  .applyPage__showcase {
    height: 80vh;
  }
  .applyPage__primary-title,
  .applyPage__primary-description {
    top: 5vh;
  }
}
@media (max-height: 450px) {
  .applyPage__waves {
    top: 80vh;
  }
  .applyPage__showcase {
    height: 80vh;
  }
  .applyPage__primary-title,
  .applyPage__primary-description {
    top: 3vh;
  }
}
@media (max-height: 400px) {
  .applyPage__waves {
    top: 105vh;
  }
  .applyPage__showcase {
    height: 105vh;
  }
  .applyPage__primary-title,
  .applyPage__primary-description {
    top: 3vh;
  }
}

.applyPage__introContainer {
  position: relative;
  overflow: hidden;
}

.applyPage__intro {
  text-align: center;
  color: black;
  width: 80%;
  background-color: white;
  align-items: center;
  margin: 5% auto;
}

.applyPage__container {
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: rgb(32, 32, 32);
  color: rgb(255, 255, 255);

  overflow: hidden;
  justify-content: flex;
}

.applyPage__projectIntroImg {
  object-fit: cover;
  width: 24vw;
  height: 30vw;
  padding: 2.5% 0% 0% 0%;
  margin-left: 7%;
  min-width: 10vw;
  min-height: 10vw;
  display: inline-block;
}
.applyPage__projectIntro {
  text-align: left;
  width: 50vw;
  background-color: rgb(32, 32, 32);
  float: right;
  padding: 5% 3% 5% 3%;
  margin-right: 4%;
}

.applyPage__projectIntro h1 {
  margin: 8px 0 15px 0;
  text-align: left;
}
.applyPage__projectIntro h2 {
  font-size: clamp(28px, calc(1.2vw + 24px), 34px);
  margin: 0;
}
.applyPage__projectIntro h3 {
  color: white;
  font-size: clamp(15px, calc(1vw + 15px), 19px);
  font-weight: normal;
  margin: 10px;
}

.applyPage__projectExample {
  justify-content: block;
  margin-bottom: 3%;
}

.applyPage__projectExample img {
  object-fit: cover;
  width: 85vw;
  max-width: 900px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.applyPage__projectExampleText {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  margin-bottom: 3%;
}
.applyPage__projectExampleText h1 {
  font-size: clamp(24px, calc(0.4vw + 24px), 30px);
  font-weight: 550;
}
@media (max-width: 850px) {
  .applyPage__projectIntroImg {
    width: 60vw;
    height: 60vw;
    margin: 10vw 20vw 0 20vw;
  }
  .applyPage__projectIntro {
    text-align: left;
    color: white;
    width: 80vw;
    padding: 0;
    margin: 5vw 10vw;
  }
  .applyPage__projectIntro h1 {
    text-align: center;
  }
}

.applyPage__applicationContainer {
  display: flex;
  min-width: none;
  color: rgb(32, 32, 32);
  background-color: white;
}

.applyPage__application {
  position: relative;
  width: 90%;
  height: auto;
  max-width: 1800px;
  justify-content: center;
  margin: 40px auto;
  margin-bottom: 10%;
}

.applyPage__applicationUL {
  list-style: none;
  padding: 0;
}

@media (min-width: 800px) {
  .applyPage__applicationUL {
    display: inline-flex;
    justify-content: center;
  }
}
.applyPage__FAQ {
  background-color: rgb(32, 32, 32);
  color: rgb(255, 255, 255);
  padding: 5% 0;
  overflow: hidden;
}

.applyPage__FAQ h2,
.applyPage__FAQ p {
  padding: 1.5%;
  margin: 0;
}

.applyPage__projectIntroContainer {
  height: fit-content;
}

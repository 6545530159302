.eventImg {
  padding-top: 10px;
  width: 75%;
  height: 60%;
  margin-left: 34px;
  margin-right: auto;
}

.alignment {
  text-align: left;
  font-size: 16px;
  margin: 1px 10px;
  color: #282a36;
}

.styling {
  width: 300px;
  height: 250px;
  border-radius: 25px;
  background-color: #d3f5b6;
  border: 1px #282a36;
  padding: 10px;
  float: left;
}
@media only screen and (max-width: 600) {
  .styling {
    width: 120px;
    height: 100px;
  }
}
.eventLink {
  text-decoration: none;
}

#eventTitle {
  font-size: 18px;
  text-align: left;
  margin: 1px 10px;
  color: #282a36;
  transition: all 0.4s ease-in-out;
}

#eventTitle:hover {
  font-size: 19px;
}

.expandableForumPost {
  position: relative;
  width: 300px;
  height: 300px;
  background: #d3f5b6;

  border-radius: 25px;
  position: relative;
  margin-bottom: 40px;
  font-family: "Inter", Tahoma, sans-serif;
  transition: width 1s, height 1s;
  padding: 10px;
  z-index: 1;
}

.expandableForumPost--active {
  height: 500px;
}

.forumPostButton {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 44%;
  border: none;
  color: none;
  background-color: Transparent;
  font-size: 20px;
  font-weight: bold;
}

.forumPostButton--active {
  transform: rotate(180deg);
}

.author {
  text-align: left;
  font-size: 16px;
  margin: 1px;
}

.date {
  text-align: left;
  font-size: 16px;
  margin: 1px;
}

.description {
  text-align: left;
  font-size: 16px;
  margin: 1px;
}

.emailButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: none;
  color: none;
  outline: none;
  background-color: Transparent;
  font-size: 15px;
  font-weight: bold;
  font-family: "Inter", Tahoma, sans-serif;
}

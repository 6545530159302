:root {
  /* CSS Variables with global scope */

  /* This turquoise isn't from the new logo, but it's what makes the stuff on our site look good*/
  --primary-green: #4dd6a8;
  --primary-black: #282a36;
  /* Web gradient: secondary blue to lime */
  --secondary-blue: #00b7ff;
  --secondary-lime: #c9e265;
  /*Apply page gradient*/
  --lightwash-green: #61c3d4;
  --baby-blue: #437eb6;
  /* Turquoise gradient: (in primary logo variant)*/
  --turquoise-green: #8bffa5;
  --turquoise-blue: #70f0e8;
  --secondary-grey: #222222f2;
  --toggle-green: #4dd6a8;
  --toggle-blue: #61c3d4;
  --title-size: clamp(1.4rem, calc(15vw + 1rem), 8rem);
  --chonky-header-size: 4.75rem;
  --thicc-subheading-size: 2.5rem;
  --babyHeading-size: 1.6rem;
  --chonky-header-weight: 900;
  --regular-font: 1.2rem;
  font-family: "Inter", Tahoma, sans-serif;

  /* Section backgrounds*/
  --dark-background: linear-gradient(
    to bottom,
    rgb(19, 19, 19),
    rgb(26, 26, 26)
  );

  --gradient-background: linear-gradient(
    to bottom,
    var(--turquoise-blue),
    var(--turquoise-green)
  );
}

.chonkyHeading {
  font-size: var(--chonky-header-size);
  font-weight: var(--chonky-header-weight);
  color: var(--primary-black);
  text-align: center;
}

.chonkyHeading--white {
  color: white;
}

.chonkyHeading--green {
  color: var(--primary-green);
}

.chonkyHeading--lessMargin {
  margin-bottom: 0px;
}

.chonkyHeading--noMargin {
  margin: 0px;
}

.chonkyHeading--dynamic {
  font-size: clamp(1rem, calc(8vw + 1rem), 3.5rem);
}

.thiccSubheading {
  font-size: var(--thicc-subheading-size);
  color: var(--primary-black);
  font-weight: 850;
  margin-block-start: 13px;
  margin-block-end: 13px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.thiccSubheading--white {
  color: white;
}
.thiccSubheading--green {
  color: var(--primary-green);
}
.thiccSubheading--gradient {
  background-clip: text;
  background-color: var(--secondary-blue);
  background-image: linear-gradient(
    45deg,
    var(--secondary-lime),
    var(--turquoise-green),
    var(--turquoise-blue),
    var(--secondary-blue)
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gradient-text {
  background-clip: text;
  background-color: var(--secondary-blue);
  background-image: linear-gradient(
    45deg,
    var(--secondary-lime),
    var(--secondary-blue)
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.thiccSubheading--weak {
  opacity: 0.95;
  font-weight: 750;
}

.babyHeading {
  color: var(--primary-black);
  font-weight: 900;
  font-size: var(--babyHeading-size);
  margin-block-start: 8px;
  margin-block-end: 8px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.babyHeading--white {
  color: white;
}

.babyHeading--grey {
  color: var(--secondary-grey);
}

.babyHeading--green {
  color: var(--primary-green);
}

.regularText,
.regularText--white {
  text-align: left;
  color: var(--secondary-grey);
  font-size: var(--regular-font);
  margin: 20px 5px;
}

.regularText--white {
  color: white;
}

.regularText--larger {
  font-size: 18px;
}

.regularText--largest {
  font-size: 1.1rem;
}

@media (max-width: 535px) {
  :root {
    --regular-font: 14px;
    --chonky-header-size: 3.2rem;
    --thicc-subheading-size: 2.1rem;
    --babyHeading-size: 1.4rem;
  }
}

@media (max-width: 390px) {
  :root {
    --chonky-header-size: 2.8rem;
    --thicc-subheading-size: 1.8rem;
    --babyHeading-size: 1.2rem;
  }
}

h1,
h2,
h3,
p,
.homePage__applyButtonBox {
  z-index: 10;
  position: relative;
}

.switch__label {
  z-index: 11;
}

a {
  color: var(--primary-green);
}

.navbar__placeholder {
  content: "";
  height: 83.6px;
}

.navbar {
  /* background:#07080A; */
  background: #07080a;
  text-align: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 83.6px;
  padding-top: 2px;
}

.navbar__logo {
  margin-top: 8px;
  height: 65px;
}
.navbar__nav-toggle {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.navbar__nav-toggle-label {
  position: absolute;
  top: 32px;
  left: 20px;
  height: 25%;
  display: flex;
  align-items: center;

  cursor: pointer;
}

.navbar__nav-toggle-label span,
.navbar__nav-toggle-label span::before,
.navbar__nav-toggle-label span::after {
  display: block;
  background: rgb(255, 255, 255);
  height: 3px;
  width: 35px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.navbar__nav-toggle-label span::before,
.navbar__nav-toggle-label span::after {
  content: "";
  position: absolute;
}
.navbar__nav-toggle-label span::before {
  bottom: 7px;
}

.navbar__nav-toggle-label span::after {
  top: 7px;
}

.navbar__content {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: #07080a;
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform ease-in-out;
}

.navbar__content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar__content li {
  margin-bottom: 1em;
  margin-left: 1em;
}

.navbar__content a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.navbar__content a:hover {
  color: var(--primary-green);
}

.navbar__nav-toggle:checked ~ nav {
  transform: scale(1, 1);
}

.navbar__nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 150ms ease-in-out 150ms;
}

@media screen and (min-width: 1400px) {
  .navbar__nav-toggle-label {
    display: none;
  }

  .navbar {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 12fr) 1fr;
  }

  .navbar__logo {
    /*position: left;
    padding-left: 4em; */
    margin-left: 20px;
    grid-column: 1 / 2;
  }

  .navbar__content {
    all: unset;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 500px;
  }

  .navbar__content ul {
    display: flex;
  }

  .navbar__content li {
    margin-left: 3em;
    margin-bottom: 0;
  }

  .navbar__content a {
    opacity: 1;
    position: relative;
  }

  .navbar__content a::before {
    content: "";
    display: block;
    height: 5px;
    background: var(--primary-green);
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  .navbar__content a:hover::before {
    transform: scale(1, 1);
  }
}

.navbar--transparent {
  opacity: 0;
  display: none;
}

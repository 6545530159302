html {
  scroll-behavior: smooth;
}

.docsPage {
  text-align: center;
  font-family: "Inter", Tahoma, sans-serif;
}

.docsPage__header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.docsPage__logo {
  width: 85%;
  max-width: 800px !important;
  margin-top: -59px;
  margin-left: auto;
  margin-right: auto;
}

/*.docsPage__h1 {
  text-align: left;
  justify-content: left;

  font-weight: 100px;
  font-size: 25px;
  font-family: "Inter", Tahoma, sans-serif;
}*/

.docsPage__links ul {
  margin-left: -20px;
  margin-top: -20px;

  text-align: left;
}

.docsPage__links {
  width: 98%;

  padding: 33px 0;
  display: flex;
  margin: auto;
  text-align: left;
}

.docsPage__links ul li {
  list-style: none;
  padding-bottom: 5px;
  margin: 0 20px;
  position: relative;
  justify-content: left;
}

.docsPage__links ul li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-family: "Inter", Tahoma, sans-serif;
  font-size: 15px;
  font-weight: 100;
}

.docsPage__links ul li::after {
  content: "";
  height: 3px;
  width: 0;
  background: var(--primary-green);
  position: absolute;
  left: 0;
  bottom: -5px;
  transition: 0.5s;
}

.docsPage__links ul li:hover::after {
  width: 100%;
}

/*.docsPage__h2 {
  text-align: right;
  justify-content: right;
  padding-right: 10px;
  font-size: 20px;
  font-family: "Inter", Tahoma, sans-serif;
}*/

/*.docsPage__links {
  text-decoration: none;
  color: white;
}
.docsPage__links:hover {
  color: rgb(183, 189, 180);
}*/

.docsPage__title {
  margin-top: 0px;
  height: 80px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "Inter", Tahoma, sans-serif;
  color: #d1e2d1;
  font-size: 30px;
}

.docsPage__search {
  margin: auto;
  position: relative;
  width: 50%;
  height: 70px;
}

.docsPage__search input {
  width: 90%;
  height: 65px;
  border-radius: 30px;
  border: 4px solid rgb(189, 209, 212);
  padding: 0 20px;
  background-color: white;
  outline: none;
  font-size: 16px;
  transition: 0.5s ease;
}

.docsPage__search input:hover {
  border: 8px solid rgb(189, 209, 212);
  background-color: rgb(189, 209, 212);
}

@media (max-width: 600px) {
  .docsPage__search input {
    width: 75%;
  }
}

/*** Contents section ***/

.docsPage__intro {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "Inter", Tahoma, sans-serif;
  font-weight: 70px;
  font-size: 23px;
  opacity: 1;
  transform: translateY(40px);
  transition: 0.5s ease-in-out;
  padding-top: 20px;
  padding-bottom: 20px;
}

.docsPage__description {
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 900;
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  font-family: "Inter", Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 50px;
}

.docsPage__content {
  text-align: left;
  color: white;
  justify-content: center;
}

@media (min-width: 600px) {
  .docsPage__content {
    width: 75%;
    margin: auto;
  }
}
.docsPage__sectionDescription {
  font-size: 19px;
}

.docsPage__content ul {
  font-size: 25px;
  list-style: none;
  padding-left: 0;
  margin: 4px 40px;
}

.docsPage__break {
  height: 60px;
}

.sectionContent li {
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 0px;
}

@media (max-width: 600px) {
  .sectionContent li {
    margin-left: -50px;
  }
}

.sectionContent a {
  color: var(--primary-green);
}

/*** Dark Mode Toggler ***/

.switch {
  overflow-y: hidden;
  white-space: nowrap;
  position: absolute;
  height: 200px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 0;
  width: 200px;

  right: 5%;
  transform-origin: 200px;
}

.switchSun {
  display: inline-block;
  position: absolute;
  font-size: 27px;
  top: 7.9px;
  height: 21px;
  left: 82%;
}

.switchMoon {
  display: inline-block;
  position: absolute;
  font-size: 30px;
  height: 20px;
  left: 37%;
  top: 10.5px;
}

.switch__label {
  position: absolute;
  display: inline-block;
  height: 24px;
  width: 52px;
  cursor: pointer;
  top: 16px;
}

.switch__label input {
  opacity: 0%;
  height: 0;
  width: 0;
}

.switch__span {
  position: absolute;
  background-color: #12b0e0;

  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 35%;
  border-bottom-left-radius: 35%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 35%;
  transition: 0.4s;
  display: inline;
}

.switch__span::before {
  content: "";
  background-color: white;

  top: 3px;
  height: 18.5px;
  width: 19px;
  font-size: 21px;
  position: absolute;
  bottom: 20px;
  border-radius: 50%;
  transition: 0.4s;
}

.switch__label input:checked + .switch__span::before {
  transform: translateX(-21px);
  background-color: rgb(255, 213, 75);
}

.switch__label input:checked + .switch__span {
  background-color: #6cbb5c;
}

.faSun {
  position: absolute;
  left: 9%;
  top: 15%;
  color: rgb(255, 213, 75);
  font-size: 18px;
  z-index: 1;
}

.faMoon {
  position: absolute;
  right: 14%;
  top: 18%;
  font-size: 15px;
  color: white;
  z-index: 1;
}

.numberStat__number {
  font-weight: 800;
  font-size: 4rem;
  transition: 0.5s ease;
}

.numberStat__number:hover {
  transform: scale(1.1);
  color: rgb(49, 49, 49);
  background-color: rgb(49, 49, 49);
  background-image: linear-gradient(rgb(49, 49, 49), rgb(54, 54, 54));
}

.numberStat__stat {
  color: var(--secondary-grey);
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 10px;
  transition: 0.5s ease;
}

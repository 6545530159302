.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*margin: auto;*/
  z-index: 10;
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*@media only screen and (max-width: 600px) {
  .popup__inner {
    left:10%;
    right:10%;
   
  }
}*/
.popup__inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 15%;
  bottom: 25%;
  margin: auto;
  z-index: 10;
}

@media (max-width: 885px) {
  .popup {
    margin-top: 0px;
  }
  .popup__inner {
    margin-top: 0px;
    left: 20%;
    right: 20%;
  }
}

@media (max-width: 600px) {
  .popup__inner {
    left: 20px;
    right: 20px;
  }
}
.popup__background {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  content: "";
}

html {
  scroll-behavior: smooth;
}
.homePage {
  box-sizing: border-box;
  overflow: hidden;
  border: none;
  margin: 0;
  font-family: "Inter", Tahoma, sans-serif;
  line-height: 1.5;
}

.homePage > * {
  position: relative;
}

.homePage__ourSections {
  background-color: rgb(12, 12, 12);
}

@supports (background-blend-mode: multiply) {
  .homePage__ourSections {
    background: radial-gradient(rgba(75, 73, 73, 0.5), rgba(41, 41, 41, 0.5));
    background-size: cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.homePage__applyButtonBox {
  padding: 2vw 0 4vw 0;
  position: relative;
  height: fit-content;
  justify-content: center;
  display: flex;
}

.homePage img {
  max-width: 100%;
  display: block;
}

.homePage__section {
  padding-top: 8em;
  padding-bottom: 5em;
}

.homePage__container {
  width: 85%;
  max-width: 65em;
  margin: 0 auto;
  color: white;
  text-align: center;
}

@media (max-width: 700px) {
  .homePage__container--noVert {
    margin-left: 7.25%;
    margin-right: 7.25%;
  }
}

.homePage__split {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  justify-content: center;
}

.homePage__split > * {
  flex-basis: 30%;
  min-width: 10em;
}

.homePage__btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: var(--clr-text, white);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.125rem;
  border-width: 0px;
  background-color: var(--primary-green);
  width: 300px;
  min-height: 40px;
  transition: transform 250ms ease-in-out;
  /* transition: width 250ms ease-in-out, height 250ms ease-in-out, font-size 250ms ease-in-out, opacity 250ms linear; */
  padding: 8px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.homePage__btn:hover {
  transform: scale(1.1);
  /* font-size: 1.2375rem;
  width: 330px;
  min-height: 44px;
  max-height: 100%; */
}

@media (max-width: 400px) {
  .homePage__btn {
    max-width: 180px;
    padding: 8px 12px;
  }
}

/*** Text ****/

.homePage__text {
  margin: 20px 5px;
  font-size: var(--regular-font);
  color: var(--secondary-grey);
  text-align: left;
}

/****** Titles **********/

.homePage__containerTitle {
  text-align: center;
  font-size: clamp(2.5rem, calc(5vw + 1rem), 4rem);
  color: var(--primary-green);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 3rem;
}

@media (max-width: 700px) {
  .homePage__headerSubtitle {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.homePage__headerSubtitle {
  font-size: 3rem;
  font-size: 5vw;
  color: white;
  z-index: 3;
  pointer-events: none;
  /*adjust size to window*/
  line-height: 1.1;
  font-weight: 700;
  margin-top: 0%;
  margin-bottom: 100%;
}

/**** Hero (Landing Page) *****/

.homePage__hero {
  text-align: center;
  height: 60vw;
  display: flex;
  flex-direction: column;
  /* background: radial-gradient(rgb(0, 0, 0), var(--secondary-grey));  */
  background-image: linear-gradient(rgb(0, 0, 0), var(--primary-black));
  position: relative;
}

@media (max-width: 950px) {
  .homePage__hero {
    text-align: center;
    height: 70vw;
    display: flex;
    flex-direction: column;
    /* background: radial-gradient(rgb(0, 0, 0), var(--secondary-grey));  */
    background-image: linear-gradient(rgb(0, 0, 0), var(--primary-black));
    position: relative;
  }
}

/* @supports (background-blend-mode: multiply) {
  .homePage__hero {
    background: radial-gradient(rgb(0, 0, 0) 60%, var(--primary-green) 110%);
    background-size: cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
  }
} */

.homePage__logoContainer {
  z-index: 3;
  position: relative;
  pointer-events: none;
}

.homePage__logo {
  width: 85%;
  max-width: 800px !important;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

/*** About Us *****/

.homePage__section--aboutUs {
  background-color: white;
  text-align: center;
  /*z-index: -2;*/
}

.homePage__numberContainer {
  margin-top: 75px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}

@media (max-width: 750px) {
  .homePage__numberContainer {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

.homePage__section--team {
  padding-top: 20px;
  padding-bottom: 0px;
}

/*** Sponsor section ***/

.homePage__section--sponsors {
  /*background-image: linear-gradient(rgba(12, 12, 12, 1), rgb(1, 115, 95));*/
  background-color: white;
  padding-bottom: 150px;
  padding-top: 20px;
  /*z-index: -2;*/
}

@media (max-width: 500px) {
  .homePage__section--sponsors {
    padding-bottom: 70px;
  }
}

#homePage__sponsorPackage:hover,
#homePage__sponsorPackage:hover {
  color: var(--primary-green);
  transform: scale(1.2);
}

.homePage__section--events {
  background-color: white;
  padding-bottom: 8em;
  /*z-index: -2;*/
}

.homePage__section--projects {
  background-image: var(--dark-background);
  color: white;
  padding: 3em 0em 8em 0em;
}

.homepage__section--projects-description-div,
.homepage__section--projects-div {
  display: grid;
}

.homepage__section--projects-description-div {
  grid-template-columns: 8fr 1fr;
}

.homepage__section--projects-div {
  grid-template-columns: 1fr 1fr;
}

.projectButtonDiv {
  margin: 3vw 0 auto 5vw;
  width: fit-content;
}

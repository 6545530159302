.particle_container {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

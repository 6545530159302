.loginDesign {
  position: absolute;
  float: left;
  background: linear-gradient(
    var(--primary-green, rgb(106, 235, 80)),
    rgb(89, 201, 209)
  );
  width: 20%;
  height: 100%;
  border-radius: 25px 0 0 25px;
}
.loginHeaderDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.registerDesign {
  position: absolute;
  float: left;
  background: linear-gradient(
    var(--primary-green, rgb(106, 235, 80)),
    rgb(89, 201, 209)
  );
  width: 20%;
  border-radius: 25px 0 0 25px;
  height: 100%;
}
.registerHeaderDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.signInBackground {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 25px;
  background-color: #282c34;
  color: white;
  font-family: "Inter", Tahoma, sans-serif;
}

.signInBackground--loginMode {
  max-width: 700px;
  width: 100%;
}

.signInBackground--registerMode {
  max-width: 700px;
  /*width:70%;*/
  width: 100%;

  /*height: 530px;*/
}
/*
@media (max-width: 1000px) {
  .signInBackground--loginMode {
    height: 340px;
  }

  .loginDesign {
    height: 340px;
  }
}

@media (max-width: 790px) {
  .signInBackground--loginMode {
    height: 370px;
  }

  .loginDesign {
    height: 370px;
  }
}
@media (max-width: 470px) {
  .signInBackground--loginMode {
    height: 385px;
  }

  .loginDesign {
    height: 385px;
  }
}
*/
/*@media (max-width: 1150px) {
  .signInBackground--registerMode {
    height: 540px;
  }
}
@media (max-width: 1060px) {
  .signInBackground--registerMode {
    height: 560px;
  }
}

@media (max-width: 970px) {
  .signInBackground--registerMode {
    height: 630px;
  }
}

@media (max-width: 905px) {
  .signInBackground--registerMode {
    height: 665px;
  }
}

@media (max-width: 805px) {
  .signInBackground--registerMode {
    height: 680px;
  }
}

@media (max-width: 675px) {
  .signInBackground--registerMode {
    height: 695px;
  }
}*/

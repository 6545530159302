.sparkOuterGrid {
  display: grid;
  grid-template-columns: 1fr 10fr;
  grid-template-rows: repeat(auto-fit);
  gap: 10px;
  min-width: fit-content;
}
@media only screen and (max-width: 600px) {
  .sparkOuterGrid {
    gap: 5px;
    grid-template-columns: 1fr;
  }
}
.sparkInnerGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 1em;
  padding: 10px;
  min-width: min-content;
}
.sparkMenuGrid {
  display: grid;
  grid-template-columns: 1;
  grid-auto-flow: dense;
  grid-gap: 1em;
  padding: 10px;
  justify-items: center;
}
.sparkBackground {
  font-family: "Inter", Tahoma, sans-serif;
  color: #99e755;
  background-color: #282a36;
  padding: 10px;
  width: fit-content !important;
  position: relative;
}
.sparkSpacing {
  height: 1em;
  border: none;
  background: #282a36;
}
.sparkTitle {
  font-family: "Inter", Tahoma, sans-serif;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}
.sparkContentTitle {
  font-family: "Inter", Tahoma, sans-serif;
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
}
.sparkContentPage {
  color: #282a36;
  background-color: #99e755;
  border-radius: 10px;
}
.sparkMenuContainer {
  grid-row: span 2;
  min-width: max-content;
}
.sparkMissionStatement {
  background-color: #282a36;
  color: #99e755;
  text-align: center;
  font-weight: 500;
  font-size: large;
  padding: 20px;
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (max-width: 600px) {
  .sparkMissionStatement {
    font-size: small;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.forumContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 1em;
  padding: 10px;
}
.forumThread {
  background-color: rgb(241, 238, 238);
  border: 1px solid rgb(0, 0, 0);
  padding: 1em;
  font-size: 18px;
  text-align: left;
}

.techspark_about {
  text-align: center;
  font-family: "Inter", Tahoma, sans-serif;
  padding-bottom: 10px;
}

#first {
  margin-top: 60px;
}

.postCreationStyling {
  display: block;
  max-width: 500px;
  width: 90%;
  height: 430px;
  border-radius: 25px;
  background-color: #282c34;
  margin: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: white;
  font-family: "Inter", Tahoma, sans-serif;
}

.postCreationHeader {
  width: 100%;
  text-align: center;
}

#postForm {
  margin-top: 12px;
  margin-bottom: 12px;
}

input[id="createPostTitle"] {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-sizing: border-box;
  resize: vertical;
  font-size: large;
  outline: none;
}

input[id="postSubmit"] {
  display: block;
  background-color: #99e755;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.postTextArea {
  display: block;
  font-family: "Inter", Tahoma, sans-serif;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 80%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-sizing: border-box;
  resize: vertical;
  height: 200px;
  outline: none;
}
